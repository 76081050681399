import { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import { useCurrentClient } from '../../../global-state/Clients';

type Prop = {
  id: string;
  subtitle: string;
};

const ReferencesRenderer: FC<{ data: Prop[] }> = (props) => {
  const currentClient = useCurrentClient((x) => x.value);

  if (!props.data.length) {
    return <>-</>;
  }

  return (
    <div className="max-h-16 overflow-y-auto overflow-x-clip">
      {props.data.map((x, i) => (
        <Link
          onClick={(e) => {
            e.stopPropagation();
          }}
          key={`link-${i}`}
          to={`/clients/${currentClient?.id}/forms/${x.id}/preview`}
          className="block underline"
          target="_blank"
          rel="noreferrer"
        >
          {x.subtitle}
        </Link>
      ))}
    </div>
  );
};

export default memo(ReferencesRenderer);
